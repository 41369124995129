<template>
  <div id="actionDialog">
    <el-dialog
      :title="!id ? '新建IP' : '编辑IP'"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form :model="form" :rules="formRules" ref="form" label-width="100px">
        <el-form-item label="IP地址" prop="remoteIp">
          <el-input
            v-model="form.remoteIp"
            placeholder="请输入IP地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册到平台">
          <el-radio-group v-model="form.allowRegistration">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirm"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      id: "",
      form: {
        remoteIp: "",
        allowRegistration: null,
        remarks: null,
      },
      formRules: {
        remoteIp: [
          { required: true, message: "请输入IP地址", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.id = "true";
      this.$set(this, "form", {
        remoteIp: "",
        allowRegistration: "",
        remarks: "",
      });
      this.$refs["form"].resetFields();
    },
    async confirm() {
      await this.$refs["form"].validate();

      try {
        this.loading = true;
        let params = { ...this.form };
        if (this.id) {
          params.id = this.id;
        }

        let res = await this.$http.post(
          this.id
            ? "/device/register/remoteIp/edit"
            : "/device/register/remoteIp/add",
          params
        );
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
