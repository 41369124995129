<template>
  <div id="remoteIp">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>注册IP列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询</el-button
            >
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="addServer"
              type="primary"
              @click="$refs.actionDialog.show = true"
              v-if="buttonList.includes('remoteIp-create')"
              ><i class="iconfont icon-jiahao"></i> 新建IP</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="remoteIp" label="IP地址" align="center">
        </el-table-column>
        <el-table-column
          prop="allowRegistration"
          label="是否允许注册到平台"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="remarks" label="备注" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="updateAt" label="最后上线时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="edit(scope.row)"
              v-if="buttonList.includes('remoteIp-edit')"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="del(scope.row.id)"
              v-if="buttonList.includes('remoteIp-delete')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <action-dialog ref="actionDialog" :getList="getList" />
  </div>
</template>
<script>
import actionDialog from "./actionDialog.vue";
export default {
  components: {
    actionDialog,
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    del(id) {
      this.$confirm("此操作将永久删除该IP, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(
            `/device/register/remoteIp/delete/${id}`
          );
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    async edit(row) {
      this.$refs.actionDialog.form = {
        remoteIp: row.remoteIp,
        allowRegistration: row.allowRegistration,
        remarks: row.remarks,
      };
      this.$refs.actionDialog.id = row.id;
      this.$refs.actionDialog.show = true;
    },
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/device/register/remoteIp/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          this.table.tableData = res.data.list.map((item) => {
            return {
              ...item,
              createAt: item.createAt
                ? this.$moment(item.createAt).format("YYYY-MM-DD HH:mm:ss")
                : "--",
              updateAt: item.updateAt
                ? this.$moment(item.updateAt).format("YYYY-MM-DD HH:mm:ss")
                : "--",
            };
          });
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#remoteIp {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .addServer {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
